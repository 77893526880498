<template>
    <div class="principles">
        <div class="section">
            <h2>Principles</h2>
            <p>I believe that people are no different than code or design. We use tools, solve problems, and imagine amazing things. The same way software works together to enrich experiences, people collaborate together to enrich lives.</p>
        </div>
        <div class="section">
            <h3>Good Design</h3>
            <h4>Dieter Rams</h4>
        </div>
        <div class="section">
            <h3>Laws of Simplicity</h3>
            <h4>John Maeda</h4>
        </div>
        <div class="section">
            <h3>“Stay hungry.<br>
                Stay foolish.”</h3>
            <h4>Steve Jobs</h4>
        </div>
        <div class="section">
            <h3>“Everyone is a genius....”</h3>
            <h4>Albert Einstein</h4>
        </div>
        <div class="section">
            <h3>“Good artists copy,<br>
                great artists steal.”</h3>
            <h4>Pablo Picasso</h4>
        </div>
    </div>
</template>
